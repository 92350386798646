<template>
    <BaseButton
        :element="NuxtLink"
        :to="url"
        :aria-label="link.ariaLabel"
        :class="
            appearance === 'link' ? 'base-button--link' : ''
        "
        @click="trackCtaClick"
    >
        <div ref="craftLinkContentRef">
            <slot name="default">{{ link.text }}</slot>
        </div>
    </BaseButton>
</template>

<script setup>
import { computed, defineProps, resolveComponent } from 'vue';
import BaseButton from '@/components/BaseButton/BaseButton.vue';
import { HOME_URI } from '~/enums/page-uri';
import { useGtm } from '@gtm-support/vue-gtm';

const NuxtLink = resolveComponent('NuxtLink');
const craftLinkContentRef = ref(null);

const props = defineProps({
    link: {
        type: Object,
        required: true,
        default: () => {}
    },
    appearance: {
        type: String,
        default: 'link',
        validator: (value) => ['link', 'button'].includes(value)
    },
    measureGtmCta: {
        type: Boolean,
        default: false
    },
});

/**
 * Given a variety of link types, return the URL to link to. Translate it, and replace any __home__ placeholders
*/
const url = computed(() => {
    if (!props.link) {
        return undefined;
    }

    let uri = null;
    if (props.link?.element?.uri) {
        uri = props.link.element.uri;
    }

    else if (props.link?.url) {
        // URL is absolute, so return it unchanged
        return props.link.url;
    }

    if (uri === HOME_URI) {
        uri = '/';
    }

    if (!uri) {
        return undefined;
    }

    // Strip any leading slashes
    uri = uri.replace(/^\/+/g, '');

    return {
        path: `/${uri}`
    };
});

const trackCtaClick = () => {
    if (props.measureGtmCta) {
        const text = craftLinkContentRef.value?.textContent || props.link.text;

        useGtm().trackEvent({
            event: 'cta_click',
            ctaText: text,
            ctaUrl: url.value?.path
        });
    }
};
</script>
